import React from 'react';
import './checkout_address.css';
import TextField from '@material-ui/core/TextField';

const FAKE_PAYMENT_DATA = {
  card: '5549 2124 1151 9935',
  code: '554',
  date: '2021-05-29',
  name: 'Joselito Sousa',
};

const CheckoutPayment = () => (
  <>
    <p>Informe os dados do Cartão de Crédito.</p>
    <form className="form">
      <div className="form-row">
        <TextField
          required
          id="payment-card"
          label="Número do Cartão"
          defaultValue={FAKE_PAYMENT_DATA['card']}
        />
      </div>
      <div className="form-row">
        <TextField
          required
          id="payment-code"
          label="Código de segurança"
          type="number"
          defaultValue={FAKE_PAYMENT_DATA['code']}
        />
        <TextField
          required
          id="payment-expire-date"
          label="Data de Expiração"
          type="date"
          defaultValue={FAKE_PAYMENT_DATA['date']}
        />
      </div>
      <div className="form-row">
        <TextField
          required
          id="payment-card-holder-name"
          label="Nome (como gravado no cartão)"
          defaultValue={FAKE_PAYMENT_DATA['name']}
        />
      </div>
    </form>
  </>
);

export default CheckoutPayment;
