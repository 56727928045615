import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

import './test_limit_modal.css';

const TestLimitModal = ({ open, setOpen }) => (
  <Modal
    open={open}
    onClose={() => setOpen(false)}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <div className="modal">
      <h2 id="simple-modal-title">Ação não permitida.</h2>
      <p id="simple-modal-description">
        Para este teste, você não pode navegar para outras páginas do sistema.
      </p>
      <Button variant="contained" onClick={() => setOpen(false)}>OK</Button>
    </div>
  </Modal>
);

export default TestLimitModal;
