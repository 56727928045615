import React from 'react';

import Divider from '@material-ui/core/Divider';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const CheckoutStepper = ({ steps, activeStep }) => (
  <>
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      style={{ backgroundColor: "transparent" }}
    >
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
    <Divider />
  </>
);

export default CheckoutStepper;
