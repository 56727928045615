import React from 'react';
import './App.css';

import Header from './shared/components/header';
import Checkout from './modules/checkout/screens/checkout';

/**
 * CoolerBid - Prototype (2020-05-27)
 * 
 * This prototype's been built by @andrelarocca to be used on nata.house's Product Owner job interviews.
 * It's a simple React app for the candidate to find minor bugs and/or implementations not complying to specs.
 * To use another context, create a new component under `/screens`, and change the content inside <section />.
 * Every new component must have a comment like this explaining which errors are intentionally implemented.
 */

function App() {
  return (
    <div className="App">
      <Header />
      <section className="App-Content">
        <Checkout />
      </section>
    </div>
  );
}

export default App;
