import React from 'react';

import Button from '@material-ui/core/Button';

const fakeError = () => {
  console.error('Oh, no :( \n An unexpected error occurred: "500: Internal Server Error"');
};

const CheckoutStepper = ({ numberOfSteps, activeStep, setStep, fakeErrorStep = null }) => {
  const handleNextStep = () => {
    if (activeStep + 1 === fakeErrorStep) {
      fakeError();
      return;
    }

    setStep(activeStep + 1);
  };
  
  const handlePreviousStep = () => {
    if (activeStep - 1 === fakeErrorStep) {
      fakeError();
      return;
    }

    setStep(activeStep - 1);
  };

  return (
    <div className="form-actions">
      <Button
        variant="contained"
        onClick={handlePreviousStep}
        disabled={activeStep - 1 < 0}
      >
        Voltar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleNextStep}
        disabled={activeStep + 1 === numberOfSteps}
      >
        Avançar
      </Button>
    </div>
  );
};

export default CheckoutStepper;
