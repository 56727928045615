import React from 'react';
import './checkout_address.css';
import TextField from '@material-ui/core/TextField';

const CheckoutAddress = () => (
  <>
    <p>Digite o endereço em que o equipamento deve ser entregue, e onde a instalação deve ocorrer.</p>
    <form className="form">
      <div className="form-row">
        <TextField
          required
          id="address-zip"
          label="CEP"
          type="number"
        />
        <TextField
          required
          id="address-street-number"
          label="Número"
          type="number"
        />
      </div>
      <div className="form-row">
        <TextField id="address-street" label="Logradouro" required />
      </div>
      <div className="form-row">
        <TextField id="address-complementary-info" label="Complemento" required />
        <TextField id="address-neighborhood" label="Bairro" required />
      </div>
      <div className="form-row">
        <TextField id="address-city" label="Cidade" required />
        <TextField id="address-state" label="Estado" required />
      </div>
    </form>
  </>
);

export default CheckoutAddress;
