import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import CheckoutStepper from '../components/checkout_stepper';
import CheckoutActions from '../components/checkout_actions';
import CheckoutPayment from './checkout_payment';
import CheckoutAddress from './checkout_address';

import './checkout.css';

/**
 * CoolerBid - Checkout (2020-05-28)
 * 
 * Checkout module for CoolerBid, written by @andrelarocca
 * Implemented phases: Payment Data, Delivery Address.
 * 
 * Known bugs:
 * 1 - [Delivery Address] Complementary Info should not be required, but it is.
 * 2 - [Delivery Address] Action to go to next page doesn't work.
*/

const STEPS = [
  'Dados de Pagamento',
  'Endereço de Entrega e Instalação',
  'Agendamento de Instalação',
  'Revisão e Confirmação'
];

const getCheckoutStep = step => ({
  0: CheckoutPayment,
  1: CheckoutAddress,
  // 2: ToBeDone
  // 3: ToBeDone 
})[step];

const Header = () => {
  const [step, setStep] = React.useState(1);

  const CheckoutStep = getCheckoutStep(step);

  return (
    <Grid container spacing={10}>
      <Grid item xs={8}>
        <Paper>
          <CheckoutStepper steps={STEPS} activeStep={step} />
          <section className="checkout-section">
            <CheckoutStep changeStep={setStep} />
            <CheckoutActions
              numberOfSteps={STEPS.length}
              activeStep={step}
              setStep={setStep}
              fakeErrorStep={2}
            />
          </section>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper style={{ textAlign: 'left' }} className="order-details">
          <span className="title">Resumo do Pedido</span>
          <Divider />
          <div className="order-data">
            <span>Ar Condicionado Split Hi Wall Electrolux Ecoturbo 12.000 BTU/h Frio R410</span>
            <span style={{ minWidth: '75px' }}>R$ 1.268,23</span>
          </div>
          <div className="order-data">
            <span>Instalação</span>
            <span style={{ minWidth: '75px' }}>R$ 500,00</span>
          </div>
          <Divider />
          <div className="order-data order-total">
            <span className="title">Total</span>
            <span style={{ minWidth: '75px' }}>R$ 1.768,23</span>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Header;
