import React from 'react';
import TestLimitModal from './test_limit_modal';

import logo from '../../assets/CoolerBid.png';
import './header.css';

const Header = () => {
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <header className="header">
      <TestLimitModal open={openModal} setOpen={setOpenModal} />
      <img src={logo} className="logo" alt="logo" onClick={() => setOpenModal(true)} />
    </header>
  );
};

export default Header;
